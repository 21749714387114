var Length = /** @class */ (function () {
    function Length() {
    }
    Length.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * Length.INCH_TO_MM; return valueMetric.toFixed(4) + ' ' + Length.METRICUNIT; };
    Length.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / Length.INCH_TO_MM; return valueImperial.toFixed(4) + ' ' + Length.IMPERIALUNIT; };
    Length.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + Length.METRICUNIT; };
    Length.displayImperial = function (valueImperial) { return valueImperial.toFixed(4) + ' ' + Length.IMPERIALUNIT; };
    Length.displayWind = function (valueWind) { return valueWind.toFixed(4) + ' ' + Length.WINDUNIT; };
    Length.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return Length.displayMetric(valueMetric);
    else
        return Length.convertMetricToImperial(valueMetric); };
    return Length;
}());
export { Length };
Length.INCH_TO_MM = 25.4;
Length.IMPERIALUNIT = 'in';
Length.METRICUNIT = 'mm';
Length.WINDUNIT = 'psf';
