var Pressure = /** @class */ (function () {
    function Pressure() {
    }
    Pressure.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * Pressure.KSI_TO_Megapascal; return valueMetric.toFixed(4) + ' ' + Pressure.METRICUNIT; };
    Pressure.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / Pressure.KSI_TO_Megapascal; return valueImperial.toFixed(4) + ' ' + Pressure.IMPERIALUNIT; };
    Pressure.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + Pressure.METRICUNIT; };
    Pressure.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return Pressure.displayMetric(valueMetric);
    else
        return Pressure.convertMetricToImperial(valueMetric); };
    return Pressure;
}());
export { Pressure };
Pressure.KSI_TO_Megapascal = 6.89476;
Pressure.IMPERIALUNIT = 'ksi';
Pressure.METRICUNIT = 'mPa';
