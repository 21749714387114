var Density = /** @class */ (function () {
    function Density() {
    }
    Density.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * Density.LbPerCubicFt_TO_KilogramPerCubicMeter; return valueMetric.toFixed(4) + ' ' + Density.METRICUNIT; };
    Density.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / Density.LbPerCubicFt_TO_KilogramPerCubicMeter; return valueImperial.toFixed(4) + ' ' + Density.IMPERIALUNIT; };
    Density.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + Density.METRICUNIT; };
    Density.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return Density.displayMetric(valueMetric);
    else
        return Density.convertMetricToImperial(valueMetric); };
    return Density;
}());
export { Density };
Density.LbPerCubicFt_TO_KilogramPerCubicMeter = 16.01846;
Density.IMPERIALUNIT = 'lb/ft3';
Density.METRICUNIT = 'kg/m3';
var NominalWeight = /** @class */ (function () {
    function NominalWeight() {
    }
    NominalWeight.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * NominalWeight.LbPerCubicFt_TO_KilogramPerCubicMeter; return valueMetric.toFixed(4) + ' ' + NominalWeight.METRICUNIT; };
    NominalWeight.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / NominalWeight.LbPerCubicFt_TO_KilogramPerCubicMeter; return valueImperial.toFixed(4) + ' ' + NominalWeight.IMPERIALUNIT; };
    NominalWeight.convertToSqFt = function (valueWeight) { var valueLb = valueWeight * NominalWeight.LbPerSqureFt_TO_KilogramPerSqure; return valueLb.toFixed(4) + ' ' + NominalWeight.SQUREUNIT; };
    NominalWeight.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + NominalWeight.METRICUNIT; };
    return NominalWeight;
}());
export { NominalWeight };
NominalWeight.LbPerCubicFt_TO_KilogramPerCubicMeter = 16.01846;
NominalWeight.LbPerSqureFt_TO_KilogramPerSqure = 20.48161;
NominalWeight.IMPERIALUNIT = 'lb/ft2';
NominalWeight.METRICUNIT = 'kg/m2';
NominalWeight.SQUREUNIT = 'lb/square';
