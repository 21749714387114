var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apolloClient } from '../ApolloClient';
import { fetchFreshToken } from '../AuthHelper';
import { Get_Rndm_UserSearchCriteriaDocument } from '../models/GQLGeneratedModels';
import { header } from '../utils/restApiHeader';
import { isRNDM } from '../utils/RNDMUtilities';
var getSearchCriteriaID = function (searchCriteriaGuid) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: Get_Rndm_UserSearchCriteriaDocument, variables: { searchCriteriaGuid: searchCriteriaGuid } }).then(function (result) { var _result$data, _result$data$RNDM_Use; return (_result$data = result.data) === null || _result$data === void 0 ? void 0 : (_result$data$RNDM_Use = _result$data.RNDM_UserSearchCriteria[0]) === null || _result$data$RNDM_Use === void 0 ? void 0 : _result$data$RNDM_Use.SearchCriteriaForConfigIT; })];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); };
var getSaveSearchLink = function (getSearchCriteria, getHasuraCriteria, getSaveValue) {
    var arguments_1 = arguments;
    return __awaiter(this, void 0, void 0, function () { var _azureADPCA$getActive, _azureADPCA$getActive2, _azureADPCA$getActive3, searchCriteriaGuid, azureADPCA, payload, userGuid, token, endPointUrl, method; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                searchCriteriaGuid = arguments_1.length > 3 && arguments_1[3] !== undefined ? arguments_1[3] : '';
                azureADPCA = arguments_1.length > 4 ? arguments_1[4] : undefined;
                payload = { searchCriteriaForConfigIT: getSearchCriteria, searchCriteriaName: getSaveValue, searchCriteriaForHasura: getHasuraCriteria, searchCriteriaGuid: searchCriteriaGuid };
                userGuid = isRNDM ? (_azureADPCA$getActive = azureADPCA.getActiveAccount()) === null || _azureADPCA$getActive === void 0 ? void 0 : _azureADPCA$getActive.localAccountId : (_azureADPCA$getActive2 = azureADPCA.getActiveAccount()) === null || _azureADPCA$getActive2 === void 0 ? void 0 : (_azureADPCA$getActive3 = _azureADPCA$getActive2.idTokenClaims) === null || _azureADPCA$getActive3 === void 0 ? void 0 : _azureADPCA$getActive3.user_uuid;
                console.log("userGuid SaveSearchCriteria ::: ", userGuid);
                return [4 /*yield*/, fetchFreshToken()];
            case 1:
                token = _a.sent();
                endPointUrl = '/search/criteria/create';
                method = 'post';
                if (searchCriteriaGuid) {
                    endPointUrl = '/search/criteria/update';
                    method = 'put';
                }
                return [4 /*yield*/, fetch(process.env.REACT_APP_RNDM_API_BASE_URL + endPointUrl, header(method, userGuid, token, payload)).then(function (response) { return { status: response.status, response: response.json() }; }).then(function (responseJson) { return responseJson; }).catch(function (error) { return { status: 'error', response: error }; })];
            case 2: return [2 /*return*/, _a.sent()];
        }
    }); });
};
export { getSaveSearchLink, getSearchCriteriaID };
