var _ConfigitCacheManager;
var ConfigitCacheManager = /** @class */ (function () {
    function ConfigitCacheManager() {
    }
    return ConfigitCacheManager;
}());
export { ConfigitCacheManager };
_ConfigitCacheManager = ConfigitCacheManager;
ConfigitCacheManager.configitCache = {}; // sort variableAssignments to avoid duplicate api calls of same input in different order
ConfigitCacheManager.getVariableAssignmentsStr = function (variableAssignments) { return JSON.stringify(variableAssignments.sort(function (a, b) { return a.variableId && b.variableId ? a.variableId > b.variableId ? 1 : a.variableId < b.variableId ? -1 : 0 : 0; })); }; // directly use data from IConfigitResponse if it is not undefined
ConfigitCacheManager.getApiResponse = function (model, variableAssignmentsStr) { if (!_ConfigitCacheManager.configitCache[model])
    _ConfigitCacheManager.configitCache[model] = {}; return _ConfigitCacheManager.configitCache[model][variableAssignmentsStr]; }; // start new api call if both data and loading from IConfigitResponse is undefined
ConfigitCacheManager.startApiCall = function (model, variableAssignmentsStr) { _ConfigitCacheManager.configitCache[model][variableAssignmentsStr] = { loading: true, targets: [] }; }; // use promise to get new IConfigitResponse if loading from IConfigitResponse is true
ConfigitCacheManager.getApiResponsePromise = function (model, variableAssignmentsStr) {
    var target = new EventTarget(); // Promise callback that triggers resolve or reject method
    var resolution = function (resolve, reject) {
        target.addEventListener('customEvent', function () { var temp = _ConfigitCacheManager.configitCache[model][variableAssignmentsStr]; if (temp.data)
            resolve(temp.data); if (temp.error)
            reject(temp.error); });
    };
    var promise = new Promise(resolution); // push targets to trigger event when api call is finished
    _ConfigitCacheManager.configitCache[model][variableAssignmentsStr].targets.push(target);
    return promise;
}; // finish api call by sending data and error from response of configit api call
ConfigitCacheManager.finishApiCall = function (model, variableAssignmentsStr, data, error) {
    var temp = _ConfigitCacheManager.configitCache[model][variableAssignmentsStr];
    temp.data = data;
    temp.loading = false;
    temp.error = error; // trigger events in each target so they can resolve their promise
    temp.targets.forEach(function (target) { return target.dispatchEvent(new Event('customEvent')); });
    temp.targets = [];
};
