var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useContext, useEffect, useState } from 'react';
import { apolloClient } from '../ApolloClient';
import { AssemblyApiRoofTypeSearchDocument, GetRoofTypeCountByFiltersDocument, SearchNavAssembliesDocument, useAssemblySearchRoofTypeCountQuery, useGetRoofTypeCountByFiltersQuery } from '../models/GQLGeneratedModels';
import { getAPISearchCriteriaBasedOnFilters } from './AssemblySearchAPIService';
import { ApplicationContext } from '../components/ApplicationProvider';
import { useLogger } from 'fmg-telemetry-react';
export var useGetRoofTypeCount = function (filters) { return { data: 50, loading: false, error: null }; };
export var useGetRoofTypesWithCount = function (filters, limit, offset, orderBy) { return useAssemblySearchRoofTypeCountQuery({ variables: { filters: filters, limit: limit, offset: offset, order_by: orderBy } }); };
export var useGetRoofTypesCountByAPIQuery = function (layers, excludedLayerTypeIds, manufacturerProduct, limit, offset, orderBy, filters) {
    var _a = useState(), data = _a[0], setData = _a[1]; //const [loading, setLoading] = useState(true);
    var logger = useLogger();
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var _d = useContext(ApplicationContext), autoRefreshSearchResult = _d.autoRefreshSearchResult, setAutoRefreshSearchResult = _d.setAutoRefreshSearchResult;
    var _e = useContext(ApplicationContext), triggerRoofTypeList = _e.triggerRoofTypeList, setTriggerRoofTypeList = _e.setTriggerRoofTypeList;
    var _f = useContext(ApplicationContext), numShowResult = _f.numShowResult, setNumShowResult = _f.setNumShowResult;
    useEffect(function () { if (filters && (autoRefreshSearchResult || triggerRoofTypeList)) {
        setLoading(true);
        if (triggerRoofTypeList)
            setTriggerRoofTypeList(false);
        var assemblySearchCriteria_1 = getAPISearchCriteriaBasedOnFilters(layers, filters, excludedLayerTypeIds, manufacturerProduct, limit, offset, orderBy);
        apolloClient.query({ query: AssemblyApiRoofTypeSearchDocument, variables: { assemblySearchCriteria: assemblySearchCriteria_1 } }).then(function (result) { setData(result.data); setLoading(result.loading); setError(result.error); }).catch(function (error) { setError(error); setLoading(false); logger.error('Error in useGetRoofTypesCountByAPIQuery', { searchCriteriaUsed: assemblySearchCriteria_1, error: error, msg: error.message }); });
    } }, [limit, offset, JSON.stringify(orderBy), JSON.stringify(filters), numShowResult]);
    return { data: data, loading: loading, error: error };
};
export var useGetAssembliesExtraLayerConfigIdFilterQuery = function (limit, offset, orderBy, layerConfigId, filters) {
    var logger = useLogger();
    var _a = useState(), data = _a[0], setData = _a[1]; //const [loading, setLoading] = useState(true);
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    useEffect(function () { if (filters) {
        setLoading(true);
        var newFilters_1 = __assign({}, filters);
        newFilters_1['LayerConfigId'] = { _eq: layerConfigId };
        apolloClient.query({ query: SearchNavAssembliesDocument, variables: { filters: newFilters_1, limit: limit, offset: offset, order_by: orderBy } }).then(function (result) { setData(result.data); setLoading(result.loading); setError(result.error); }).catch(function (error) { setError(error); setLoading(false); logger.error('Error in useGetAssembliesExtraLayerConfigIdFilterQuery', { searchCriteriaUsed: { filters: newFilters_1, limit: limit, offset: offset, order_by: orderBy }, error: error, msg: error.message }); });
    } }, [limit, offset, JSON.stringify(orderBy), JSON.stringify(filters)]);
    return { data: data, loading: loading, error: error };
};
export var getRoofTypeCountByFilters = function (filters) { return __awaiter(void 0, void 0, void 0, function () { var _result$data$vNavAsse, result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetRoofTypeCountByFiltersDocument, variables: { filters: filters } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, (_result$data$vNavAsse = result.data.vNavAssembly_RoofTypeCount_aggregate.aggregate) === null || _result$data$vNavAsse === void 0 ? void 0 : _result$data$vNavAsse.count];
    }
}); }); };
export var useGetRoofTypeCountByFilters = function (filters) { var _data$vNavAssembly_Ro; var _a = useGetRoofTypeCountByFiltersQuery({ variables: { filters: filters } }), data = _a.data, loading = _a.loading, error = _a.error; return { data: data === null || data === void 0 ? void 0 : (_data$vNavAssembly_Ro = data.vNavAssembly_RoofTypeCount_aggregate.aggregate) === null || _data$vNavAssembly_Ro === void 0 ? void 0 : _data$vNavAssembly_Ro.count, loading: loading, error: error }; };
