var Strength = /** @class */ (function () {
    function Strength() {
    }
    Strength.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * Strength.KSI_TO_Megapascal; return valueMetric.toFixed(4) + ' ' + Strength.METRICUNIT; };
    Strength.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / Strength.KSI_TO_Megapascal; return valueImperial.toFixed(4) + ' ' + Strength.IMPERIALUNIT; };
    Strength.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + Strength.METRICUNIT; };
    Strength.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return Strength.displayMetric(valueMetric);
    else
        return Strength.convertMetricToImperial(valueMetric); };
    return Strength;
}());
export { Strength };
Strength.KSI_TO_Megapascal = 6.89476;
Strength.IMPERIALUNIT = 'ksi';
Strength.METRICUNIT = 'mPa';
