var VolumePerUnitArea = /** @class */ (function () {
    function VolumePerUnitArea() {
    }
    VolumePerUnitArea.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * VolumePerUnitArea.GALLONSSQUARE_TO_LITERSSQUAREMETER; return valueMetric.toFixed(4) + ' ' + VolumePerUnitArea.METRICUNIT; };
    VolumePerUnitArea.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / VolumePerUnitArea.GALLONSSQUARE_TO_LITERSSQUAREMETER; return valueImperial.toFixed(4) + ' ' + VolumePerUnitArea.IMPERIALUNIT; };
    VolumePerUnitArea.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + VolumePerUnitArea.METRICUNIT; };
    VolumePerUnitArea.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return VolumePerUnitArea.displayMetric(valueMetric);
    else
        return VolumePerUnitArea.convertMetricToImperial(valueMetric); };
    return VolumePerUnitArea;
}());
export { VolumePerUnitArea };
VolumePerUnitArea.GALLONSSQUARE_TO_LITERSSQUAREMETER = 0.407458;
VolumePerUnitArea.IMPERIALUNIT = 'gal/square';
VolumePerUnitArea.METRICUNIT = 'l/m2';
