var _a;
import { createSlice } from '@reduxjs/toolkit';
var note = [];
var roofAreaData = [];
var roofAreaContact = [];
var roofAreaNote = [];
var project = [];
var scenarios = [];
var scenarioStatus = [];
var scenarioType = [];
var initialState = { note: note, project: project, roofArea: roofAreaData, roofAreaContact: roofAreaContact, roofAreaNote: roofAreaNote, scenarios: scenarios, scenarioStatus: scenarioStatus, scenarioType: scenarioType };
export var projectSlice = createSlice({ name: 'projects', initialState: initialState, // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setProject: function (state, action) { state.project = action.payload; }, setNote: function (state, action) { state.note = action.payload; }, setRoofArea: function (state, action) { state.roofArea = action.payload; }, setRoofAreaContact: function (state, action) { state.roofAreaContact = action.payload; }, setScenarios: function (state, action) { state.scenarios = action.payload; }, setScenarioStatus: function (state, action) { state.scenarioStatus = action.payload; }, setScenarioType: function (state, action) { state.scenarioType = action.payload; }
    } });
export var setProject = (_a = projectSlice.actions, _a.setProject), setNote = _a.setNote, setRoofArea = _a.setRoofArea, setRoofAreaContact = _a.setRoofAreaContact, setScenarios = _a.setScenarios, setScenarioStatus = _a.setScenarioStatus, setScenarioType = _a.setScenarioType;
export default projectSlice.reducer;
