var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apolloClient } from '../ApolloClient';
import { GetListScenarioDetailByEmailDocument, GetScenarioContractorPackageInfoDocument, GetScenarioDetailDocument, GetScenarioFullInfoDocument, GetSinglePlyAdhesiveRelatedUsagesDocument, GetUserScenarioListDocument, SaveScenarioDocument } from '../models/GQLGeneratedModels'; //import { IScenarioForAPI } from '../pages/AssemblyDetail/SaveModal';
export var saveAssemblyScenario = function (scenarioToSave, operationType) { return __awaiter(void 0, void 0, void 0, function () {
    var _result$data, _result$data2, _result$data2$scenari, scenarioInfoAndOperator, result, resultScenarioId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                scenarioInfoAndOperator = { Scenario: scenarioToSave, SaveOperationType: operationType };
                return [4 /*yield*/, apolloClient.query({ query: SaveScenarioDocument, variables: { scenarioInfoSave: scenarioInfoAndOperator } })];
            case 1:
                result = _a.sent();
                resultScenarioId = 0;
                if ((_result$data = result.data) !== null && _result$data !== void 0 && _result$data.scenario_api_savescenario)
                    resultScenarioId = (_result$data2 = result.data) === null || _result$data2 === void 0 ? void 0 : (_result$data2$scenari = _result$data2.scenario_api_savescenario[0]) === null || _result$data2$scenari === void 0 ? void 0 : _result$data2$scenari.ScenarioId;
                return [2 /*return*/, resultScenarioId];
        }
    });
}); };
export var deleteAssemblyScenario = function (scenarioToSave) { return __awaiter(void 0, void 0, void 0, function () {
    var _result$data3, _result$data4, _result$data4$scenari, scenarioInfoAndOperator, result, resultScenarioId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                scenarioInfoAndOperator = { Scenario: scenarioToSave, SaveOperationType: 3 };
                return [4 /*yield*/, apolloClient.query({ query: SaveScenarioDocument, variables: { scenarioInfoSave: scenarioInfoAndOperator } })];
            case 1:
                result = _a.sent();
                resultScenarioId = 0;
                if ((_result$data3 = result.data) !== null && _result$data3 !== void 0 && _result$data3.scenario_api_savescenario)
                    resultScenarioId = (_result$data4 = result.data) === null || _result$data4 === void 0 ? void 0 : (_result$data4$scenari = _result$data4.scenario_api_savescenario[0]) === null || _result$data4$scenari === void 0 ? void 0 : _result$data4$scenari.ScenarioId;
                return [2 /*return*/, resultScenarioId];
        }
    });
}); };
export var getSavedScenarioByEmail = function (userEmail, randomId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetListScenarioDetailByEmailDocument, variables: { UserEmail: userEmail, RandomId: randomId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.Scenario];
    }
}); }); };
export var getScenarioDetailById = function (scenarioId, userEmail, randomId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetScenarioDetailDocument, variables: { ScenarioId: scenarioId, AzureB2CUserEmail: userEmail, RandomId: randomId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.Scenario];
    }
}); }); };
export var getScenarioFullInfoById = function (scenarioId, userEmail, randomId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetScenarioFullInfoDocument, variables: { ScenarioId: scenarioId, AzureB2CUserEmail: userEmail, RandomId: randomId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.Scenario];
    }
}); }); };
export var getScenarioContractorPackageInfo = function (scenarioId, userEmail, randomId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetScenarioContractorPackageInfoDocument, variables: { ScenarioId: scenarioId, AzureB2CUserEmail: userEmail, RandomId: randomId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data];
    }
}); }); };
export var getSinglePlyAdhesiveRelatedUsagesByNavAssemblyId = function (navAssemblyId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetSinglePlyAdhesiveRelatedUsagesDocument, variables: { NavAssemblyId: navAssemblyId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.NavAssembly];
    }
}); }); };
export var getScenarioDetailByEmail = function (userEmail) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetListScenarioDetailByEmailDocument, variables: { UserEmail: userEmail } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.Scenario];
    }
}); }); };
export var genrateUuidv4 = function () { return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : uuid & 0x3 | 0x8; return uuid.toString(16); }); };
export var getUnassignedScenarioList = function (userEmail, randomId) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({ query: GetUserScenarioListDocument, variables: { UserEmail: userEmail, RandomId: randomId } })];
            case 1:
                result = _a.sent();
                return [2 /*return*/, result.data.Scenario.filter(function (item) { return item.R_RoofAreaScenarios.length === 0; })];
        }
    });
}); };
