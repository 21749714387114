var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useContext, useEffect, useState } from 'react';
import { apolloClient } from '../ApolloClient';
import { ProductApiSearchDocument, ProductApiListingDocument, GetProductDetailAllInfoDocument, GetComponentDocByIdDocument, ProductApiMatchedAssemblyCountDocument } from '../models/GQLGeneratedModels';
import { ConfigitProductContext } from '../components/ConfigitProductProvider';
import { useLogger } from 'fmg-telemetry-react';
export var useProductApiSearchQuery = function (manufacturerId, componentId, categoryId, subCategoryId, approvedUseId, attributes, countryId) { var logger = useLogger(); var _a = useState(), data = _a[0], setData = _a[1]; var _b = useState(true), loading = _b[0], setLoading = _b[1]; var _c = useState(), error = _c[0], setError = _c[1]; var variableAssignments = useContext(ConfigitProductContext).variableAssignments; useEffect(function () { var productSearchCriteria = { manufacturerId: manufacturerId, componentId: componentId, categoryId: categoryId, subCategoryId: subCategoryId, approvedUseId: approvedUseId, attributes: attributes, countryId: countryId }; apolloClient.query({ query: ProductApiSearchDocument, variables: { productSearchCriteria: productSearchCriteria } }).then(function (result) { setData(result.data); setLoading(result.loading); setError(result.error); }).catch(function (error) { setLoading(false); setError(error); logger.error('Error in useProductApiSearchQuery', { searchCriteriaUsed: productSearchCriteria, error: error, msg: error.message }); }); }, [JSON.stringify(variableAssignments)]); return { data: data, loading: loading, error: error }; };
export var useGetProductListByAPIQuery = function (iniSearchCriteria, pageNumber, rowsPerPage, sortColumn, sortDirection, targetApprovedUseId, targetCategoryId, targetSubCategoryId) { var logger = useLogger(); var _a = useState(), data = _a[0], setData = _a[1]; var _b = useState(true), loading = _b[0], setLoading = _b[1]; var _c = useState(), error = _c[0], setError = _c[1]; useEffect(function () { if (iniSearchCriteria) {
    setLoading(true);
    var productSearchCriteriaWithTarget_1 = { iniSearchCriteria: iniSearchCriteria, pageNumber: pageNumber, rowsPerPage: rowsPerPage, sortColumn: sortColumn, sortDirection: sortDirection, targetApprovedUseId: targetApprovedUseId, targetCategoryId: targetCategoryId, targetSubCategoryId: targetSubCategoryId };
    productSearchCriteriaWithTarget_1.rowsPerPage = rowsPerPage;
    productSearchCriteriaWithTarget_1.pageNumber = pageNumber / rowsPerPage + 1;
    apolloClient.query({ query: ProductApiListingDocument, variables: { productSearchCriteriaWithTarget: productSearchCriteriaWithTarget_1 } }).then(function (result) { setData(result.data); setLoading(result.loading); setError(result.error); }).catch(function (error) { setError(error); setLoading(false); logger.error('Error in useGetProductListByAPIQuery', { searchCriteriaUsed: productSearchCriteriaWithTarget_1, error: error, msg: error.message }); });
} }, [rowsPerPage, pageNumber, sortDirection, JSON.stringify(sortColumn), JSON.stringify(iniSearchCriteria)]); return { data: data, loading: loading, error: error }; };
export var getProductDetailByComponentId = function (componentId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetProductDetailAllInfoDocument, variables: { ComponentId: componentId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data];
    }
}); }); };
export var getProductDocumentByDocumentId = function (ComponentDocId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetComponentDocByIdDocument, variables: { ComponentDocId: ComponentDocId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.ComponentDoc];
    }
}); }); };
export var getProductMatchedAssemblyCount = function (componentId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: ProductApiMatchedAssemblyCountDocument, variables: { producMatchAssemblyCountCriteria: { componentId: componentId } } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data];
    }
}); }); };
