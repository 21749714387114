var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apolloClient } from '../ApolloClient';
import { GetCountryListDocument, GetProjectsWithChildRoofAreaScenarioByEmailDocument, GetRoofProjectBaseInfoByIdDocument, GetRoofProjectsByEmailDocument, GetStateProvinceListByCountryDocument, SaveUserProjectDocument } from '../models/GQLGeneratedModels';
import { useLogger } from 'fmg-telemetry-react';
export var getCountries = function () { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetCountryListDocument })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.C_Country];
    }
}); }); };
export var getStatesProvincesByCountry = function (countryId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetStateProvinceListByCountryDocument, variables: { CountryId: countryId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.R_Country_StateProvince];
    }
}); }); }; // need to pass data as project, operation type
export var saveUserProject = function (projectToSave) { return __awaiter(void 0, void 0, void 0, function () { var logger, result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            logger = useLogger();
            return [4 /*yield*/, apolloClient.query({ query: SaveUserProjectDocument, variables: { saveProjectBaseInfo: projectToSave } }).then(function (result) { var _result$data, _result$data2, _result$data2$roofpro; var resultProjectId = 0; if ((_result$data = result.data) !== null && _result$data !== void 0 && _result$data.roofproject_api_savebaseinfo)
                    resultProjectId = (_result$data2 = result.data) === null || _result$data2 === void 0 ? void 0 : (_result$data2$roofpro = _result$data2.roofproject_api_savebaseinfo[0]) === null || _result$data2$roofpro === void 0 ? void 0 : _result$data2$roofpro.ProjectId; return resultProjectId; }).catch(function (error) { logger.error('Error in saveUserProject', { searchCriteriaUsed: projectToSave, error: error, msg: error.message }); })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result];
    }
}); }); };
export var getProjectsByEmail = function (userEmail, randomId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetRoofProjectsByEmailDocument, variables: { UserEmail: userEmail, RandomId: randomId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.RoofProject];
    }
}); }); };
export var getProjectByProjectId = function (projectId, randomId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetRoofProjectBaseInfoByIdDocument, variables: { ProjectId: projectId, RandomId: randomId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.RoofProject];
    }
}); }); };
export var getProjectsWithChildRoofAreaScenarioByEmail = function (userEmail, randomId) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetProjectsWithChildRoofAreaScenarioByEmailDocument, variables: { UserEmail: userEmail, RandomId: randomId } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.RoofProject];
    }
}); }); };
