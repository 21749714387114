var DisplayText = /** @class */ (function () {
    function DisplayText() {
    }
    DisplayText.BoardSize = function (width, length, isMetric) { if (width && length) {
        if (isMetric) {
            return "".concat(width.toFixed(2), " x ").concat(length.toFixed(2), " mm");
        }
        return "".concat(width.toFixed(2), " x ").concat(length.toFixed(2), " in");
    }
    else {
        return;
    } };
    return DisplayText;
}());
export { DisplayText };
