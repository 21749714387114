var _a;
import { createSlice } from '@reduxjs/toolkit'; // note: some type conversion (or just type changes on redux side) may be needed when integrating calculator API
// Current types subject to change
var externalFireRating = { value: undefined, name: 'None Selected' };
var internalFireRating = { value: undefined, name: 'None Selected' };
var hailRating = { value: '', name: 'None Selected' };
var enclosureClassification = { value: -1, name: 'None Selected' };
var surfaceRoughness = { value: -1, name: '' };
var fireState = { internalFireRating: internalFireRating, externalFireRating: externalFireRating };
var hailState = { hailRating: hailRating };
var windState = { cycloneProneLocation: false, enclosureClassification: enclosureClassification, hasParapet: false, roofAreaHeight: '', roofAreaLength: '', roofAreaSlope: '', roofAreaWidth: '', surfaceRoughness: surfaceRoughness, windSpeed: '', isRiseRun: true };
var initialState = { calculatorIndex: 0, showCalc: false, fireRatingState: fireState, hailRatingState: hailState, windRatingState: windState, isWindComplete: false };
export var calculatorSlice = createSlice({ name: 'calculator', initialState: initialState, // The `reducers` field lets us define reducers and generate associated actions
    reducers: { jumpToIndex: function (state, action) { state.calculatorIndex = action.payload; }, nextSection: function (state) {
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // technically check isn't needed, but including for now
            if (state.calculatorIndex < 3) {
                state.calculatorIndex += 1;
            }
        }, previousSection: function (state) { state.calculatorIndex -= 1; }, resetState: function (state) { state = initialState; }, setCycloneProne: function (state, action) { state.windRatingState.cycloneProneLocation = action.payload; }, setEnclosureClassification: function (state, action) { state.windRatingState.enclosureClassification = action.payload; }, setExternalFire: function (state, action) { state.fireRatingState.externalFireRating = action.payload; }, setHail: function (state, action) { state.hailRatingState.hailRating = action.payload; }, setHasParapet: function (state, action) { state.windRatingState.hasParapet = action.payload; }, setInternalFire: function (state, action) { state.fireRatingState.internalFireRating = action.payload; }, setIsRiseRun: function (state, action) { state.windRatingState.isRiseRun = action.payload; }, setIsWindComplete: function (state, action) { state.isWindComplete = action.payload; }, setRoofAreaHeight: function (state, action) { state.windRatingState.roofAreaHeight = action.payload; }, setRoofAreaLength: function (state, action) { state.windRatingState.roofAreaLength = action.payload; }, setRoofAreaSlope: function (state, action) { state.windRatingState.roofAreaSlope = action.payload; }, setRoofAreaWidth: function (state, action) { state.windRatingState.roofAreaWidth = action.payload; }, setSurfaceRoughness: function (state, action) { state.windRatingState.surfaceRoughness = action.payload; }, // currently this value is a string- once data is plugged in, may change
        setWindSpeed: function (state, action) { state.windRatingState.windSpeed = action.payload; }, toggleCalc: function (state, action) { state.showCalc = action.payload; } } });
export var jumpToIndex = (_a = calculatorSlice.actions, _a.jumpToIndex), nextSection = _a.nextSection, previousSection = _a.previousSection, resetState = _a.resetState, setCycloneProne = _a.setCycloneProne, setEnclosureClassification = _a.setEnclosureClassification, setExternalFire = _a.setExternalFire, setHail = _a.setHail, setHasParapet = _a.setHasParapet, setInternalFire = _a.setInternalFire, setIsRiseRun = _a.setIsRiseRun, setIsWindComplete = _a.setIsWindComplete, setRoofAreaHeight = _a.setRoofAreaHeight, setRoofAreaLength = _a.setRoofAreaLength, setRoofAreaSlope = _a.setRoofAreaSlope, setRoofAreaWidth = _a.setRoofAreaWidth, setSurfaceRoughness = _a.setSurfaceRoughness, setWindSpeed = _a.setWindSpeed, toggleCalc = _a.toggleCalc;
export default calculatorSlice.reducer;
