var Force = /** @class */ (function () {
    function Force() {
    }
    Force.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / Force.N_TO_LBF; return valueImperial.toFixed(4) + ' ' + Force.IMPERIALUNIT; };
    return Force;
}());
export { Force };
Force.N_TO_LBF = 4.448;
Force.IMPERIALUNIT = 'lbf';
Force.METRICUNIT = 'N';
