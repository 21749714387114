var Area = /** @class */ (function () {
    function Area() {
    }
    Area.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * Area.SQUAREFEET_TO_SQUAREMETERS; return valueMetric.toFixed(4) + ' ' + Area.METRICUNIT; };
    Area.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / Area.SQUAREFEET_TO_SQUAREMETERS; return valueImperial.toFixed(4) + ' ' + Area.IMPERIALUNIT; };
    Area.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + Area.METRICUNIT; };
    Area.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return Area.displayMetric(valueMetric);
    else
        return Area.convertMetricToImperial(valueMetric); };
    return Area;
}());
export { Area };
Area.SQUAREFEET_TO_SQUAREMETERS = 0.0929;
Area.IMPERIALUNIT = 'ft2';
Area.METRICUNIT = 'm2';
