var SolidApplicationRate = /** @class */ (function () {
    function SolidApplicationRate() {
    }
    SolidApplicationRate.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * SolidApplicationRate.POUNDSQUARE_TO_KILOSSQUAREMETER; return valueMetric.toFixed(4) + ' ' + SolidApplicationRate.METRICUNIT; };
    SolidApplicationRate.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / SolidApplicationRate.POUNDSQUARE_TO_KILOSSQUAREMETER; return valueImperial.toFixed(4) + ' ' + SolidApplicationRate.IMPERIALUNIT; };
    SolidApplicationRate.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + SolidApplicationRate.METRICUNIT; };
    SolidApplicationRate.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return SolidApplicationRate.displayMetric(valueMetric);
    else
        return SolidApplicationRate.convertMetricToImperial(valueMetric); };
    return SolidApplicationRate;
}());
export { SolidApplicationRate };
SolidApplicationRate.POUNDSQUARE_TO_KILOSSQUAREMETER = 0.04882428;
SolidApplicationRate.IMPERIALUNIT = 'lb/square';
SolidApplicationRate.METRICUNIT = 'kg/m2';
