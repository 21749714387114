var _process$env$REACT_AP; // TODO: check which of these variables are needed, which need to be modified, etc
var appNodeEnv = process.env.REACT_APP_NODE_ENV;
var applicationName = (_process$env$REACT_AP = process.env.REACT_APP_TELEMETRY_APP_NAME) !== null && _process$env$REACT_AP !== void 0 ? _process$env$REACT_AP : 'FMG.Approvals.RoofNavNext.UI.EnvVarsNotLoaded';
var exportToConsole = process.env.REACT_APP_FMG_TELEMETRY_EXPORT_TO_CONSOLE !== 'false'; // Sends all telemetry to console. Very noisy.
var localOnlyMode = process.env.REACT_APP_FMG_TELEMETRY_LOCAL_ONLY_MODE !== 'false'; // Does not contact Dynatrace, Sends Log statements to console. Use this flag during development of new logging.
var logToConsole = process.env.REACT_APP_FMG_TELEMETRY_LOG_TO_CONSOLE !== 'false'; // Sends Log statements to console.
var allowedTraceUrls = [];
var logLevel = process.env.REACT_APP_LOG_LEVEL;
var environment = process.env.REACT_APP_ENVIRONMENT;
var dtConfig = process.env.REACT_APP_DYNATRACE_CONFIG;
if (appNodeEnv !== 'production') {
    allowedTraceUrls.push(/http:\/\/localhost:8080/);
    allowedTraceUrls.push(/http:\/\/localhost:3000/);
    allowedTraceUrls.push(/^http:\/\/localhost:5100(?:\/[\w-]+)*(?:\?[\w=&-]+)?/);
} // Basic config needed for FMG Telemetry context
var FMGTelemetrySetup = { servicename: applicationName, allowedTraceUrls: allowedTraceUrls, exportToConsole: exportToConsole, localOnlyMode: localOnlyMode, logToConsole: logToConsole, logLevel: logLevel, environment: environment, dtConfig: dtConfig };
export default FMGTelemetrySetup;
