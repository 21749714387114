var MassPerUnitArea = /** @class */ (function () {
    function MassPerUnitArea() {
    }
    MassPerUnitArea.convertImperialToMetric = function (valueImperial) { var valueMetric = valueImperial * MassPerUnitArea.OzPerSquareFt_TO_KILOSSQUAREMETER; return valueMetric.toFixed(4) + ' ' + MassPerUnitArea.METRICUNIT; };
    MassPerUnitArea.convertMetricToImperial = function (valueMetric) { var valueImperial = valueMetric / MassPerUnitArea.OzPerSquareFt_TO_KILOSSQUAREMETER; return valueImperial.toFixed(4) + ' ' + MassPerUnitArea.IMPERIALUNIT; };
    MassPerUnitArea.displayMetric = function (valueMetric) { return valueMetric.toFixed(4) + ' ' + MassPerUnitArea.METRICUNIT; };
    MassPerUnitArea.display = function (valueMetric, showAsMetric) { if (showAsMetric)
        return MassPerUnitArea.displayMetric(valueMetric);
    else
        return MassPerUnitArea.convertMetricToImperial(valueMetric); };
    return MassPerUnitArea;
}());
export { MassPerUnitArea };
MassPerUnitArea.OzPerSquareFt_TO_KILOSSQUAREMETER = 0.305152;
MassPerUnitArea.IMPERIALUNIT = 'oz/ft2';
MassPerUnitArea.METRICUNIT = 'kg/m2';
