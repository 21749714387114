// For component layer type id and approved use id 1-1 mapping
// LayerType 23 - Coating/Surfacing and LayerType 37 - Vapor Retarder may have multiple approved uses. They are not yet implemented.
//SELECT LayerTypeId, ApprovedUseId, CAST(LayerTypeId AS VARCHAR(2)) + ': ' + CAST(ApprovedUseId AS VARCHAR(2)) + ','
//FROM ApprovedLayerUse WHERE IsSecurement = 0 AND LayerTypeId NOT IN (23,37) ORDER BY LayerTypeId
export var COMPONENTLAYERTYPEAPPROVEDUSE_MAP = { 1: 8, 2: 68, 3: 69, 4: 9, 5: 10, 6: 59, 7: 57, 8: 58, 9: 16, 10: 17, 11: 18, 12: 19, 14: 20, 16: 81, 17: 23, 19: 24, 21: 4, 22: 5, 24: 11, 26: 33, 27: 35, 28: 36, 29: 38, 30: 22, 31: 40, 33: 46, 34: 45, 36: 48, 38: 12 }; // For component layer type id and subcategory id 1-1 mapping
// SELECT LayerTypeId, SubcategoryId, CAST(LayerTypeId AS VARCHAR(2)) + ': ' + CAST(SubcategoryId AS VARCHAR(2)) + ','
// FROM ApprovedLayerUse INNER JOIN R_ApprovedUse_Subcategory
// 	ON ApprovedLayerUse.ApprovedUseId = R_ApprovedUse_Subcategory.ApprovedUseId
// WHERE IsSecurement = 0 AND LayerTypeId NOT IN (23,37) ORDER BY LayerTypeId
export var COMPONENTLAYERTYPESUBCATEGORY_MAP = { 1: 3, 4: 6, 5: 7, 6: 4, 7: 4, 8: 4, 9: 5, 10: 5, 11: 5, 12: 8, 14: 9, 17: 12, 19: 13, 21: 20, 22: 21, 24: 17, 26: 36, 27: 16, 28: 17, 29: 19, 30: 11, 31: 37, 33: 33, 34: 23, 36: 17, 38: 30 };
